// export const API_URL = "http://backend.bfaf.test/api";
// export const API_URL = "https://api.bfaf.thewickfirm.com/api";

//LOCAL API
// export const API_URL = "http://127.0.0.1:8000/api";

//STAGING API
export const API_URL = "https://stagingapi.bfaf.ae/api";

//LIVE API
//export const API_URL = "https://api.bfaf.ae/api";



